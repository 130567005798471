import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { useMemo } from 'react';

export const useNominationsTab = () => {
    // FIXME: move to useCurrentUser hook, requires moving nominationsState out of schedulable.js and into ProgramTypeConfigs.ts
    const currentUser = useCurrentUserIguanaObject();

    const nominationsTab = useMemo(
        () => ({
            nominationState: currentUser?.relevantCohort?.nominationsState,
        }),
        [currentUser],
    );

    return nominationsTab;
};
