import { useAppHeaderViewModel } from 'Navigation';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Footer } from 'Resources/Footer';

export function Layout() {
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { t } = useTranslation();

    AppHeaderViewModel.setBodyBackground('beige');
    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(t('nominations.nominations.nominations_title').toLocaleUpperCase());

    return (
        <div className="tw-flex tw-animate-fade-in tw-flex-col sm:tw-min-h-[calc(100vh-75px)]">
            <div className="tw-relative tw-flex-grow">
                <Outlet />
            </div>
            <Footer className="tw-flex-shrink-0" />
        </div>
    );
}
