import { Container } from 'Container';
import { SlopeBackground } from 'Resources/SlopeBackground';
import { Typography } from 'Typography';
import { FormCard } from 'Nominations/FormCard';
import { type FormSectionProps } from './FormSection.types';

export function FormSection({ nominationState, title, formCard }: FormSectionProps) {
    return (
        <section className="tw-relative tw-overflow-hidden tw-pb-16 tw-pt-9 md:tw-pb-4 md:tw-pt-[7.25rem]">
            {nominationState === 'preNomination' && (
                <>
                    <SlopeBackground />
                    <Container className="tw-relative">
                        <Typography
                            variant="header2"
                            className="tw-pt-[3.75rem] tw-uppercase tw-text-beige-beyond-beyond-dark xs:tw-pt-24 md:tw-pt-12 lg:tw-pt-0"
                        >
                            {title}
                        </Typography>
                        <FormCard {...formCard} />
                    </Container>
                </>
            )}
        </section>
    );
}
