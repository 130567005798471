import { Typography } from 'Typography';
import { Image } from 'Image';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { Container } from 'Container';
import { type DescriptionSectionProps } from './DescriptionSection.types';

export function DescriptionSection({ content, image }: DescriptionSectionProps) {
    return (
        <section className="tw-bg-white tw-pt-6 md:tw-pb-10 md:tw-pt-[5.25rem]">
            <Container>
                <div className="tw-mx-auto tw-max-w-[555px] tw-space-y-8 md:tw-max-w-full md:tw-space-y-13.5">
                    <div className="tw-flex tw-flex-col-reverse md:tw-grid md:tw-grid-cols-12 md:tw-gap-7.5">
                        <div className="tw-space-y-7.5 md:tw-col-span-6">
                            {content.map(item => (
                                <div key={item.id} className="tw-space-y-2.5">
                                    {item.title && (
                                        <Typography variant="header2" className="tw-text-grey-darker">
                                            {item.title}
                                        </Typography>
                                    )}
                                    {item.description && (
                                        <Typography
                                            variant="body2"
                                            className="tw-text-xs tw-leading-[28px] tw-text-beige-beyond-beyond-dark md:tw-text-[16px] md:tw-leading-[30px]"
                                        >
                                            {parseHtml(item.description)}
                                        </Typography>
                                    )}
                                    {item.list && (
                                        <ul className="tw--mt-[6px] tw-list-outside tw-list-disc tw-leading-[28px] md:tw-mt-0 md:tw-leading-[30px] ltr:tw-pl-6 md:ltr:tw-pl-7 rtl:tw-pr-7">
                                            {item.list.map(listItem => (
                                                <li
                                                    key={listItem.item}
                                                    className="tw-text-xs tw-text-beige-beyond-beyond-dark md:tw-text-[16px] ltr:tw-pl-2.5 rtl:tw-pr-3"
                                                >
                                                    {parseHtml(listItem.item)}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="tw--mt-2 tw-block tw-pb-5 md:tw-col-span-6 md:tw-mt-0 md:tw-pt-0 lg:tw-col-span-5 lg:tw-col-start-8">
                            <div className="tw-flex tw-items-center tw-justify-center md:tw--mt-1 md:tw-justify-end">
                                <Image {...image} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
