import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useTargetBrandConfig } from 'FrontRoyalAngular';
import { InstitutionId } from 'Institutions';

import scholarshipQuanticPrestigePerson from 'images/nominations/nominations-quantic-scholarship-prestige-person.png';
import scholarshipQuanticPrestigePersonRetina from 'images/nominations/nominations-quantic-scholarship-prestige-person@2x.png';
import scholarshipValarPrestigePerson from 'images/nominations/nominations-valar-scholarship-prestige-person.png';
import scholarshipValarPrestigePersonRetina from 'images/nominations/nominations-valar-scholarship-prestige-person@2x.png';
import applicationReceivedMobile from 'images/nominations/nominations-application-received.png';
import applicationReceivedMobileRetina from 'images/nominations/nominations-application-received@2x.png';
import { type FormSectionProps } from '../FormSection/FormSection.types';
import { FormSection } from '../FormSection';
import { type DescriptionSectionProps } from '../DescriptionSection/DescriptionSection.types';
import { DescriptionSection } from '../DescriptionSection';
import { useNominationsTab } from '../useNominationsTab';

export function ScholarshipNominations() {
    const { t } = useTranslation('back_royal');
    const currentUser = useCurrentUser();
    const { nominationState } = useNominationsTab();
    const targetBrandConfig = useTargetBrandConfig();
    const isValar = targetBrandConfig.institutionId === InstitutionId.valar;
    const brandNameShort = targetBrandConfig.brandNameShort;

    const description: DescriptionSectionProps = useMemo(
        () => ({
            header: {
                title: t('nominations.nominations.nominations_title'),
            },
            content: [
                {
                    id: 0,
                    title: t('nominations.nominations.class_nomination_officer_title'),
                    description: t('nominations.nominations.class_nomination_officer_description', {
                        brandName: brandNameShort,
                    }),
                },
                {
                    id: 1,
                    list: [
                        { item: t('nominations.nominations.class_nomination_officer_list_item_one') },
                        { item: t('nominations.nominations.class_nomination_officer_list_item_two') },
                        {
                            item: t('nominations.nominations.class_nomination_officer_list_item_three', {
                                brandName: brandNameShort,
                            }),
                        },
                    ],
                },
            ],
            image: {
                mobileImage: {
                    width: 192,
                    height: 192,
                    images: {
                        default: isValar ? scholarshipValarPrestigePerson : scholarshipQuanticPrestigePerson,
                        retina: isValar ? scholarshipValarPrestigePersonRetina : scholarshipQuanticPrestigePersonRetina,
                    },
                },
                desktopImage: {
                    width: 350,
                    height: 350,
                    images: {
                        default: isValar ? scholarshipValarPrestigePerson : scholarshipQuanticPrestigePerson,
                        retina: isValar ? scholarshipValarPrestigePersonRetina : scholarshipQuanticPrestigePersonRetina,
                    },
                },
                alt: 'Scholarship prestige person illustration',
            },
        }),
        [t, isValar, brandNameShort],
    );

    const form: FormSectionProps = useMemo(
        () => ({
            nominationState,
            title: t('nominations.nominations.form_section_title'),
            formCard: {
                image: {
                    mobileImage: {
                        width: 40,
                        height: 40,
                        images: {
                            default: applicationReceivedMobile,
                            retina: applicationReceivedMobileRetina,
                        },
                    },
                    desktopImage: {
                        width: 51,
                        height: 51,
                        images: {
                            default: applicationReceivedMobile,
                            retina: applicationReceivedMobileRetina,
                        },
                    },
                    alt: 'Green check mark illustration',
                },
                submittedTitle: t('nominations.nominations.form_section_submitted_title'),
                submittedDescription: t('nominations.nominations.form_section_submitted_description'),
                formData: {
                    formId: 'MFqBXw9l',
                    hiddenFields: {
                        name: currentUser?.name || undefined,
                        email: currentUser?.email || undefined,
                    },
                },
            },
        }),
        [t, currentUser, nominationState],
    );

    return (
        <div>
            <DescriptionSection {...description} />
            <FormSection {...form} />
        </div>
    );
}
