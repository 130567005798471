import { Widget } from '@typeform/embed-react';
import { type FormEmbedProps } from './FormEmbed.types';

export function FormEmbed({ formId, hiddenFields, handleFormSubmission }: FormEmbedProps) {
    return (
        <Widget
            id={formId}
            hidden={{
                name: hiddenFields.name ?? '',
                email: hiddenFields.email ?? '',
            }}
            className="tw-absolute tw-h-full tw-w-full"
            onSubmit={handleFormSubmission}
        />
    );
}
