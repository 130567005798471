import { useCallback, useState } from 'react';
import { Typography } from 'Typography';
import { FormEmbed } from 'Nominations/FormEmbed';
import { Image } from 'Image';
import { type FormCardProps } from './FormCard.types';

export function FormCard({ submittedTitle, submittedDescription, formData, image }: FormCardProps) {
    const [isFormSubmitted, setIsFormSubmitted] = useState<string>(
        window.localStorage.getItem('scholarshipNominationsFormSubmitted') ?? 'false',
    );

    const handleFormSubmission = useCallback(() => {
        setIsFormSubmitted('true');
        window.localStorage.setItem('scholarshipNominationsFormSubmitted', 'true');
    }, []);

    return (
        <div className="tw-mx-auto tw-max-w-[555px] md:tw-max-w-full">
            <div className="tw-relative tw-mx-auto tw-mt-8 tw-flex tw-min-h-[400px] tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-md tw-bg-white tw-shadow-lg tw-shadow-black/5 md:tw-mb-16 md:tw-min-h-[540px]">
                {isFormSubmitted === 'true' ? (
                    <div className="tw-flex tw-flex-col tw-items-center">
                        <Image {...image} className="tw-mb-5 md:tw-mb-10" />
                        <Typography
                            variant="header2"
                            className="tw-mb-1 tw-text-center tw-font-extrabold tw-text-beige-beyond-beyond-dark md:tw-mb-4"
                        >
                            {submittedTitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            className="tw-max-w-[220px] tw-text-center tw-text-xs tw-leading-[24px] tw-text-beige-beyond-beyond-dark md:tw-max-w-none md:tw-text-md"
                        >
                            {submittedDescription}
                        </Typography>
                    </div>
                ) : (
                    <FormEmbed {...formData} handleFormSubmission={handleFormSubmission} />
                )}
            </div>
        </div>
    );
}
